'use strict';

angular.module('enervexSalesappApp').controller('NavbarCtrl', function($scope, $state, $location, $window, Auth, $stateParams, $document, Util, CommandManager) {
	$scope.menu = [
		{
			'title': 'enervex.com',
			'link': 'https://enervex.com'
		}
	];
	$scope.menuItems = [
		{ url: '/appliance-categories', name: 'Appliance Categories' },
		{ url: '/appliance-companies', name: 'Appliance Companies' },
		{ url: '/appliance-utility', name: 'Appliance Utility' },
		{ url: '/applications', name: 'Applications' },
		{ url: '/application-subtypes', name: 'Application Subtypes' },
		{ url: '/baffles', name: 'Baffles' },
		{ url: '/baffle-types', name: 'Baffle Types' },
		{ url: '/baffle-voltages', name: 'Baffle Voltages' },
		{ url: '/bom-rules', name: 'BOM Rules' },
		{ url: '/cache', name: 'Cache' },
		{ url: '/controls', name: 'Controls' },
		{ url: '/diameters', name: 'Diameters' },
		{ url: '/customer-companies', name: 'Fancalc Companies' },
		{ url: '/fan-phases', name: 'Fan Phases' },
		{ url: '/fan-voltages', name: 'Fan Voltages' },
		{ url: '/fans', name: 'Fans' },
		{ url: '/fittings', name: 'Fittings' },
		{ url: '/fuels', name: 'Fuel' },
		{ url: '/icons', name: 'Icons' },
		{ url: '/insulation-types', name: 'Insulation Types' },
		{ url: '/job-statuses', name: 'Job Statuses' },
		{ url: '/lineitem-sources', name: 'Lineitem Sources' },
		{ url: '/location-datas', name: 'Location Datas' },
		{ url: '/manufacturers', name: 'Manufacturer' },
		{ url: '/metal-types', name: 'Metal Types' },
		{ url: '/mod-types', name: 'Mod Types' },
		{ url: '/plume-coilhands', name: 'Plume Coilhands' },
		{ url: '/plume-headers', name: 'Plume Headers' },
		{ url: '/products', name: 'Products' },
		{ url: '/product-subtypes', name: 'Product Subtypes' },
		{ url: '/product-types', name: 'Product Types' },
		{ url: '/properties', name: 'Properties' },
		{ url: '/proposal-tables', name: 'Proposal Tables' },
		{ url: '/residential-company-types', name: 'Residential Company Types' },
		{ url: '/savings-goals', name: 'Savings Goals' },
		{ url: '/stack-rules', name: 'Stack Rules' },
		{ url: '/stack-rule-phases', name: 'Stack Rule Phases' },
		{ url: '/system-tasks', name: 'System Tasks' },
		{ url: '/templates', name: 'Template Items' },
		{ url: '/users', name: 'Users' },
		{ url: '/vent-materials', name: 'Vent Materials' },
		{ url: '/voltages', name: 'Voltages' },
	]

	$scope.accountId = $stateParams.accountId;
	$scope.accountLocationId = $stateParams.accountLocationId;
	$scope.jobId = $stateParams.jobId;
	$scope.isCollapsed = true;
	$scope.isLoggedIn = Auth.isLoggedIn;
	$scope.notLoggedIn = function() {
		return !Auth.isLoggedIn();
	}
	$scope.isAdmin = Auth.isAdmin;
	$scope.isUserAdmin = Auth.isUserAdmin;
	$scope.isCommercial = Auth.isCommercial;
	$scope.getCurrentUser = Auth.getCurrentUser;
	$scope.logout = function() {
		Auth.logout().then(function(data) {
			if (data.redirect){
				window.location.href = data.redirect;
			} else {
				$state.go("login", {

				}, {
					reload: true,
					inherit: false
				})
			}
		});
	};
	$scope.flushCache = function() {
		CommandManager.flushCache()
	}
	$scope.isActive = function(route) {
		return route === $location.path();
	};
	if( $location.$$path.substring(0, 9) === '/accounts'){
		$scope.accountActive = true;
	}
	$scope.today = new Date();
	$scope.allIcon = true;
	$scope.accountsIcon = false;
	$scope.jobsIcon = false;
	$scope.productsIcon = false;
	$scope.showDropdown = false;
	$scope.version = Util.versionInfo().version
	$scope.searchAll = function(){
		$scope.allIcon = true;
		$scope.accountsIcon = false;
		$scope.jobsIcon = false;
		$scope.productsIcon = false;
		$scope.showDropdown = false;
	}
	$scope.searchAccounts = function(){
		$scope.allIcon = false;
		$scope.accountsIcon = true;
		$scope.jobsIcon = false;
		$scope.productsIcon = false;
		$scope.showDropdown = false;
	}
	$scope.searchJobs = function(){
		$scope.allIcon = false;
		$scope.accountsIcon = false;
		$scope.jobsIcon = true;
		$scope.productsIcon = false;
		$scope.showDropdown = false;
	}
	$scope.searchProducts = function(){
		$scope.allIcon = false;
		$scope.accountsIcon = false;
		$scope.jobsIcon = false;
		$scope.productsIcon = true;
		$scope.showDropdown = false;
	}
	$scope.closeDropdown = function(){
		$scope.showDropdown = false;
	}
	$(window).on("load, resize", function() {
		var viewportWidth = $(window).width();
		if (viewportWidth < 1164) {
			$(".navbar-collapse.container").removeClass("container");
		} else {
			$(".navbar-collapse").addClass("container");
		}
	});

	$scope.loginPage = function(viewLocation) {
	    return viewLocation === $location.path();
	};

	$scope.registrationPage = function(viewLocation) {
		return viewLocation === $location.path().substring(0, 14);
	}


});
